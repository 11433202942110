import React from "react";
import { graphql } from "gatsby";
import EditorialContentComponent from "../../components/editorial-content";
import { useI18next } from "gatsby-plugin-react-i18next";
import PrivateRoute from "../../components/private-route";
export const query = graphql`
  query EditorialContentProducts($Slug: String!, $language: String!) {
    editorialContentProductsEn: strapiEditorialContent(
      Slug: { eq: $Slug }
      locale: { eq: "en" }
    ) {
      Slug
      AlternateSlug
      Title
      editorial_content_type {
        Name
        Slug
      }
      therapeutical_area {
        Name
        Slug
        id
      }
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      Thumbnail {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContentSeeMoreThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentDownloads {
        localFile {
          url
        }
      }
    }
    editorialContentProductsEs: strapiEditorialContent(
      Slug: { eq: $Slug }
      locale: { eq: "es-PA" }
    ) {
      Slug
      AlternateSlug
      Title
      Description
      editorial_content_type {
        Name
        Slug
      }
      therapeutical_area {
        Name
        Slug
        id
      }
      Thumbnail {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContentSeeMoreThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentDownloads {
        localFile {
          url
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const EditorialContentProductsComponent = ({ data }) => {
  const { language, t } = useI18next();
  if (!data) return null;

  return (
    <EditorialContentComponent
      type="products"
      editorialContent={
        language === "en"
          ? data.editorialContentProductsEn
          : data.editorialContentProductsEs
      }
      parentTitle={t("nav.products")}
      parentLink="/products"
    />
  );
};

const EditorialContentProducts = ({ data }) => {
  return (
    <PrivateRoute component={EditorialContentProductsComponent} data={data} />
  );
};

export default EditorialContentProducts;
